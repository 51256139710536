<template>
  <div>
    <div
      class="dressUpTheCharacter"
      v-for="taskInstance in data.options"
      :set="
        (selectedItem = selectedItemById.find(
          ({ id }) => id === taskInstance.id
        ).value)
      "
      :key="taskInstance.id"
    >
      <div
        :set="(idItems = items.find(({ id }) => id === taskInstance.id).values)"
      >
        <div class="characterContainer">
          <div class="characterBodyParts">
            <template v-for="item in idItems">
              <template v-if="isDressed(taskInstance.id, item.value)">
                <img
                  class="dressedItem"
                  :style="{ 'z-index': item.zIndex }"
                  :src="item.dressedSrc"
                  :alt="item.name"
                  :key="'dressed_' + item.value"
                />
              </template>
            </template>
            <img
              class="character"
              :src="taskInstance.mainImageSrc"
              alt="Hahmo"
              draggable="false"
            />
          </div>
        </div>

        <div class="itemSelector">
          <div class="arrowButton" @click="previousItem(taskInstance.id)">
            <span class="glyphicons glyphicons-chevron-left"></span>
          </div>
          <div class="viewedItem">
            <div class="items">
              <template
                v-for="(item, key) in idItems"
                :key="'item_' + item.value"
              >
                <img
                  :class="[{ selectedItem: selectedItem === key }, 'item']"
                  :src="item.src"
                  :alt="item.name"
                  @click="setItem(taskInstance.id, key)"
                />
              </template>
            </div>
          </div>
          <div class="arrowButton" @click="nextItem(taskInstance.id)">
            <span class="glyphicons glyphicons-chevron-right"></span>
          </div>
        </div>
        <div class="itemInfo">
          <span>
            {{ idItems[selectedItem].name }}
            <div class="answer" @click="setItem(taskInstance.id, selectedItem)">
              <div
                class="checkbox noselect"
                :class="{
                  selected: isDressed(
                    taskInstance.id,
                    idItems[selectedItem].value
                  ),
                }"
              ></div>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeMount, computed, ref } from "vue";
import { useAnswer } from "../useAnswer";
import { getDeepCopy } from "@utils/General";

/*
Example data
{
    "options": [
        {
        "id": 1,
        "mainImageSrc": "https://example.com/character.jpg",
        "values": [
            {
            "name": "Turvakengät",
            "src": "https://example.com/turvakengat.jpg",
            "dressedSrc": "https://example.com/turvakengat_puettu.jpg",
            "zIndex": 1,
            "excludeParts": [
                "shoes"
            ],
            "isDressed": false,
            "value": 1
            },
        }
    ],
    "answer": [
        {
        "id": 1,
        "value": [
            1,
            4,
            5,
        ]
        }
    ]
}
*/

export default {
  name: "DressUpTheCharacterBackup",
  props: ["data", "isTest", "taskId"],
  setup(props) {
    const { createRefAnswer, setAnswers } = useAnswer();

    const answers = createRefAnswer(props.taskId);
    const selectedItemById = ref([]); // Selected items by ID
    const items = ref([]);

    onBeforeMount(() => {
      if (props.data.options) {
        items.value = createItems(props.data.options);
        selectedItemById.value = createSelectedItemById(props.data.options);
        setAnswers(answers, createAnswers(props.data.options));
      }
    });

    function createItems(data) {
      let obj = [];

      for (let i in data) {
        obj.push({
          id: data[i].id,
          values: getDeepCopy(data[i].values),
        });
      }
      return obj;
    }

    function createSelectedItemById(data) {
      let obj = [];

      for (let i in data) {
        obj.push({
          id: data[i].id,
          value: 0,
          //value: data[i].values[0].value,
        });
      }
      return obj;
    }

    function createAnswers(data) {
      let obj = [],
        value;

      for (let i in data) {
        value = [];
        for (let j in data[i].values) {
          if (data[i].values[j].isDressed) {
            value.push(data[i].values[j].value);
          }
        }

        obj.push({
          id: data[i].id,
          value: value,
        });
      }
      return obj;
    }

    function isDressed(_id, itemValue) {
      const idAnswers = answers.value.find(({ id }) => id === _id);
      return idAnswers.value.indexOf(itemValue) !== -1 ? true : false;
    }

    function previousItem(_id) {
      const idItems = items.value.find(({ id }) => id === _id);
      const selectedItem = selectedItemById.value.find(({ id }) => id === _id);

      if (selectedItem.value - 1 < 0) {
        selectedItem.value = idItems.values.length - 1;
      } else {
        selectedItem.value--;
      }

      //if ()

      // if (selectedItemsById.value-1 < 0) {
      //     selectedItemsById.value = items.value.length-1;
      // } else {
      //     selectedItemsById.value--;
      // }
    }

    function nextItem(_id) {
      const idItems = items.value.find(({ id }) => id === _id);
      const selectedItem = selectedItemById.value.find(({ id }) => id === _id);

      if (selectedItem.value + 1 > idItems.values.length - 1) {
        selectedItem.value = 0;
      } else {
        selectedItem.value++;
      }

      // if (selectedItem.value+1 > items.value.length-1) {
      //     selectedItem.value = 0;
      // } else {
      //     selectedItem.value++;
      // }
    }

    function checkIfExcluded(a, b) {
      for (var i in a) {
        for (var j in b) {
          if (a[i] === b[j]) {
            return true;
          }
        }
      }
      return false;
    }

    function setItem(_id, _index) {
      const idItems = items.value.find(({ id }) => id === _id);

      if (idItems && idItems.values[_index]) {
        const item = idItems.values[_index];

        const idAnswers = answers.value.find(({ id }) => id === _id);
        const itemIndex = idAnswers.value.indexOf(item.value);
        // If already dressed
        if (itemIndex !== -1) {
          idAnswers.value.splice(itemIndex, 1);
        } else {
          for (let i in idItems.values) {
            if (item.excludeParts && idItems.values[i].excludeParts) {
              if (
                checkIfExcluded(
                  item.excludeParts,
                  idItems.values[i].excludeParts
                )
              ) {
                const _itemIndex = idAnswers.value.indexOf(
                  idItems.values[i].value
                );
                if (_itemIndex !== -1) {
                  idAnswers.value.splice(_itemIndex, 1);
                }
              }
            }
          }
          idAnswers.value.push(item.value);
        }
      }
      // if (items.value[index]) {
      //     // If already dressed
      //     if (items.value[index].isDressed) {
      //         items.value[index].isDressed = false;
      //     } else {
      //         for (var i in items.value) {
      //             if (items.value[index].excludeParts && items.value[i].excludeParts) {
      //                 if (checkIfExcluded(items.value[index].excludeParts, items.value[i].excludeParts)) {
      //                     items.value[i].isDressed = false;
      //                 }
      //             }
      //         }
      //         items.value[index].isDressed = true;
      //     }

      //     //setAnswers(this.items);
      // }
    }

    return {
      selectedItemById: computed(() => selectedItemById.value),
      items: computed(() => items.value),
      answers: computed(() => answers.value),
      previousItem,
      nextItem,
      setItem,
      isDressed,
    };
  },
};
</script>

<style lang="scss">
@import 'tasks.scss';

.dressUpTheCharacter {
  position: relative;
  padding-bottom: 30px;

  .dressedItem {
    position: absolute;
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }

  .character {
    display: flex;
    margin: auto;
  }
  .characterBodyParts {
    position: relative;
    /*display: inline-flex;*/
    width: 100%;
    margin: auto;
    padding-right: 80px;
    padding-left: 80px;
    /*
        display: flex;
        justify-content: center;
        */
  }
  .characterContainer {
    display: flex;
  }

  .itemSelector {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    align-items: center;
    max-width: 400px;
    margin: auto;

    .viewedItem {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .items {
      position: relative;
      width: 150px;
      height: 150px;
    }

    .arrowButton {
      font-size: 40px;
      cursor: pointer;
    }

    .item {
      display: none;
      opacity: 1;
      cursor: pointer;

      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0;

      max-height: 100%;

      &.selectedItem {
        opacity: 1;
        display: block;
      }
    }
  }
  .itemInfo {
    display: flex;
    justify-content: center;

    .answer {
      display: inline-block;
      margin-left: 10px;
      margin-top: 20px;
    }
  }
}
</style>
